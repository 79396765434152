<template>
	<div class="cient-list-contianer">
		<div class="my-form-container">
			<el-form :inline="true" :model="searchForm" class="my-form">
				<el-form-item label="客户名称：">
					<el-input v-model="searchForm.customer_name" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="联系方式：">
					<!-- <el-select v-model="searchForm.contact_type" placeholder="请选择">
						<el-option v-for="item in contact_type" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select> -->
					<el-input v-model="searchForm.contact_info" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="类型：">
					<!-- <el-input v-model="searchForm.account_type" placeholder="请输入"></el-input> -->
					<el-select v-model="searchForm.account_type" placeholder="请选择" filterable>
						<el-option label="全部" value="">
						</el-option>
						<el-option label="伙伴" value="1">
						</el-option>
						<el-option label="客户" value="2">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="实名状态：">
					<el-select v-model="searchForm.realname_status" placeholder="请选择">
						<el-option v-for="item in realname_status" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="签约状态：">
					<el-select v-model="searchForm.contract_status" placeholder="请选择" clearable>
						<el-option label="--空--" value="0">
						</el-option>
						<el-option label="已签约" :value="2"></el-option>
						<el-option label="未签约" :value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="已开通二级平台：">
					<el-select v-model="searchForm.is_open" placeholder="请选择" clearable>
						<el-option label="--空--" value="0">
						</el-option>
						<el-option label="已开通" :value="2"></el-option>
						<el-option label="未开通" :value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="伙伴折扣：">
					<el-input v-model="searchForm.discount" placeholder="请输入" type="number"></el-input>
				</el-form-item>
				<el-form-item label="BD：">
					<!-- <el-input v-model="searchForm.BD" placeholder="请输入"></el-input> -->
					<el-select v-model="searchForm.BD" placeholder="请选择" filterable>
						<el-option label="全部" value="">
						</el-option>
						<el-option v-for="item in BDList" :key="item.username" :label="item.username"
							:value="item.username">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div class="form-button">
				<el-button style="background-color: #3B91FF; color: #FFFFFF;" @click="getDataSearch()">查询</el-button>
				<el-button style="background-color: #FFFFFF; color: #666666;" @click="searchForm = {}">重置</el-button>
				<el-button style="background-color: #FFFFFF; color: #666666;" @click="exportExcel()">导出</el-button>
			</div>
		</div>
		<div class="my-table">
			<div style="display: flex;">
				<div class="invite-button" style="margin-left: 1.7rem; margin-bottom: .8rem;border-radius: .2rem;"
					@click="openAdd('1')">
					<img src="../../assets/uid/jiahao.png">
					新增客户
				</div>
				<!-- <div class="invite-button" style="margin-left: 1.7rem; margin-bottom: .8rem;border-radius: .2rem;"
					@click="openYq">
					<img src="../../assets/uid/jiahao.png">
					邀请注册
				</div> -->
			</div>
			<el-table :data="tableData" style="width: 100%" @sort-change="changeSort" empty-text="抱歉，暂无数据！"
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55">
				</el-table-column>
				<el-table-column fixed prop="customer_name" label="客户名称" width="180">
				</el-table-column>
				<el-table-column prop="contact_info" label="联系方式" min-width="150">
					<template slot-scope='scope'>
						<div style="display: flex; align-items: center;">
							{{scope.row.contact_info}}
							<img style="width: 20px; height: 20px; margin-left: 12px;"
								:src="getContactImg(scope.row.contact_type)" />
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="account_type" label="类型" min-width="80">
					<template slot-scope="scope">
						{{scope.row.account_type == 1?'伙伴':'客户'}}
					</template>
				</el-table-column>
				<el-table-column prop="realname_status" label="实名状态" width="120">
					<template slot-scope="scope">
						<div class="free-dj">
							<div :class="{'free':scope.row.realname_status == 2,'dj':scope.row.realname_status == 1}">
							</div>
							{{scope.row.realname_status == 1?"未实名":"已线下实名"}}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="contract_status" label="签约状态" width="140">
					<template slot-scope="scope">
						{{scope.row.contract_status == 2?"已签约":scope.row.contract_status == 1?"未签约":""}}
					</template>
				</el-table-column>
				<el-table-column prop="is_open" label="已开通二级平台" width="140">
					<template slot-scope="scope">
						{{scope.row.is_open == 2?"已开通":scope.row.is_open == 1?"未开通":""}}
					</template>
				</el-table-column>
				<el-table-column prop="discount" label="伙伴折扣" width="140">
					<template slot-scope="scope"><span v-if="scope.row.discount">{{scope.row.discount}}%</span></template>
				</el-table-column>
				<el-table-column prop="associated_count" label="已关联账号数" width="140" sortable="custom">
				</el-table-column>
				<el-table-column prop="BD" label="BD" width="120">
					<!-- <template slot-scope="scope">
					</template> -->
				</el-table-column>
				<el-table-column prop="create_time" label="创建时间" min-width="120">
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="202">
					<template slot-scope="scope">
						<el-button type="text" size="small" @click="openAdd('2',scope.row)">客户管理</el-button>
						<el-button style="color: #FF4D4F;" @click="deleteClientDialog = true;delId = scope.row.sid"
							type="text" size="small">
							删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="my-page" style="justify-content: space-between; padding-left: 10px;">
				<el-button
					style="background-color: #3B91FF; color: #FFFFFF; width: 94px; height: 40px; font-size: 14px;"
					@click="downloadExcel()" :disabled="multipleSelection.length == 0">
					<div style="display: flex;align-items: center;justify-content: center;">
						<img src="../../assets/customer/expoer.png"
							style="width: 16px; height: 16px; margin-right: 4px;">
						导出
					</div>
				</el-button>
				<el-pagination background layout="prev, pager, next, sizes, jumper" @size-change="changeSize"
					:total="total" :current-page="searchForm.page" @current-change="changePage">
				</el-pagination>
			</div>
		</div>
		<el-dialog class="my-dialog invite-dialog" title="邀请注册" :visible.sync="DialogVisible" width="30%" center
			:close-on-click-modal="$store.state.modalClose">
			<el-form :model="inviteForm" ref="inviteForm" :rules="inviteRules" label-width="7.8rem">
				<el-form-item label="分销账号：" prop="distribution_account">
					<el-select v-model="inviteForm.distribution_account" placeholder="请选择分销账号" @change="changeFx">
						<el-option v-for="(item,index) in distribution_account" :label="item.label" :key="index"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="接收邮箱：" prop="email">
					<el-input v-model="inviteForm.email" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="企业名称：" prop="unit" style="display: flex; align-items: flex-start;">
					<el-input v-model="inviteForm.unit" placeholder="请输入"></el-input>
					<!-- <span style="margin-top: 10px; font-size: 8px; color: #adadad;">注：请按此格式填写：<span
							style="color: #db7c7c;">【BD】邮箱或BD-邮箱</span></span> -->
				</el-form-item>
				<el-form-item label="客户：" prop="customer_sid">
					<!-- <el-input v-model="inviteForm.customer_sid" placeholder="请输入"></el-input>  @visible-change="changeVis" -->
					<el-select v-model="inviteForm.customer_sid" placeholder="请选择" filterable
						:remote-method="remoteCustomer" clearable @clear="remoteCustomer('')" :loading="remoteLoading"
						remote>
						<el-option v-for="(item,index) in customerInviteList" :label="item.customer_name" :key="index"
							:value="item.sid"></el-option>
						<div class="add-select" @click="DialogVisible=false;openAdd(1)">新增客户</div>
					</el-select>
				</el-form-item>
				<el-form-item label="验证码：" prop="pic_code">
					<div style="display: flex; align-items: center; height: 30px;">
						<el-input style="flex: 1;" v-model="inviteForm.pic_code" placeholder="验证码"></el-input>
						<div style="width: 100px;cursor: pointer;" @click="reSetCode">
							<img ref="codeImg" :src="codeUrl">
						</div>
					</div>
				</el-form-item>
			</el-form>
			<span slot="footer" class="my-dialog-footer">
				<el-button @click="DialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="commitYq">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog class="my-dialog invite-dialog" :title="dialogTitle" :visible.sync="addClientDialog" width="30%"
			center :close-on-click-modal="$store.state.modalClose">
			<el-form class="my-dialog invite-dialog" ref="clientForm" :model="clientForm" :rules="clientFormRules"
				label-width="7.8rem">
				<el-form-item label="客户名称：" prop="customer_name">
					<el-input v-model="clientForm.customer_name" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="联系方式：" prop="contact_info" class="contact-form-item">
					<el-radio-group v-model="clientForm.contact_type" @change="changeContactType">
						<el-radio :label="1">TG账号</el-radio>
						<el-radio :label="3">skype账号</el-radio>
						<el-radio :label="2">手机号</el-radio>
						<el-radio :label="4">邮箱</el-radio>
					</el-radio-group>
					<el-input v-model="clientForm.contact_info" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="关联账号数：" prop="associated_count" v-if="openAddType == 2">
					<el-input v-model="clientForm.associated_count" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="BD：" prop="BD_id">
					<!-- <el-input v-model="clientForm.BD" placeholder="请输入"></el-input> -->
					<el-select v-model="clientForm.BD_id" placeholder="请选择" filterable>
						<el-option v-for="item in BDList" :key="item.id" :label="item.username" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="类型：" prop="account_type">
					<el-radio-group v-model="clientForm.account_type">
						<el-radio :label="2">客户</el-radio>
						<el-radio :label="1">伙伴</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="实名状态：" prop="realname_status" v-if="clientForm.account_type == 1">
					<el-radio-group v-model="clientForm.realname_status">
						<el-radio :label="1">未实名</el-radio>
						<el-radio :label="2">已线下实名</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="签约状态：" prop="contract_status" v-if="clientForm.account_type == 1">
					<el-radio-group v-model="clientForm.contract_status">
						<el-radio :label="2">已签约</el-radio>
						<el-radio :label="1">未签约</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="已开通二级平台：" prop="is_open" v-if="clientForm.account_type == 1">
					<el-radio-group v-model="clientForm.is_open">
						<el-radio :label="2">已开通</el-radio>
						<el-radio :label="1">未开通</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="伙伴折扣：" prop="discount" v-if="clientForm.account_type == 1">
					<div style="display: flex;align-items: center;">
						<el-input style="width: 120px;margin-right: 15px;" v-model="clientForm.discount" type="number" placeholder="请输入数值"></el-input> %
					</div>
				</el-form-item>
				<el-form-item label="创建时间：" prop="create_time" v-if="openAddType == 2">
					<span style="color: #111111; font-size: 14px;">{{clientForm.create_time}}</span>
				</el-form-item>
			</el-form>
			<span slot="footer" class="my-dialog-footer">
				<el-button @click="addClientDialog = false">取 消</el-button>
				<el-button type="primary" @click="commitAddClient">{{openAddType == 1?'确定新增':'确定'}}</el-button>
			</span>
		</el-dialog>
		<el-dialog class="my-dialog delete-dialog" title="删除账号" :visible.sync="deleteClientDialog" width="30%" center
			:close-on-click-modal="$store.state.modalClose">
			<div class="attention">
				<img src="../../assets/attention.png">
				此操作不可逆，请谨慎确认！！
			</div>
			<span slot="footer" class="my-dialog-footer">
				<el-button @click="deleteClientDialog = false">取 消</el-button>
				<el-button type="danger" class="error-button" @click="commitDel()">确定删除</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import mixin from '../../mixins.js'
	import {
		list,
		add,
		del,
		edit,
		// excel,
		checkExcel,
	} from '../../api/customer.js'
	import {
		BDList,
		customerInviteList,
		inviteRegister
	} from '../../api/uid.js'
	import {
		export_json_to_excel
	} from '@/excel/export2Excel'
	import {
		downloadFileByBase64
	} from '../../utils.js'
	export default {
		mixins: [mixin],
		data() {
			var validateDiscount = (rule, value, callback) => {
				console.log('discount' + value)
				if (value == "" || !value) {
					callback(new Error('请输入折扣'));
				} else {
					if (!(/(^[1-9]\d*$)/.test(value)) || Number(value) < 10) {
						// this.$refs.ruleForm.validateField('checkPass');
						callback(new Error('请输入正确折扣（两位正整数）'));
					} else {
						callback();
					}
				}
			};
			var validateEmail = (rule, value, callback) => {
				console.log('email' + value)
				if (value == "" || !value) {
					console.log('请输入邮箱')
					callback(new Error('请输入邮箱'));
				} else {
					if (!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)) {
						// this.$refs.ruleForm.validateField('checkPass');
						callback(new Error('请输入正确的邮箱'));
					} else {
						callback();
					}
				}
			};
			var validateBDEmail = (rule, value, callback) => {
				if (value == "" || !value) {
					console.log('请输入')
					callback(new Error('请输入正确格式邮箱'))
					// callback(new Error('请输入正确格式【BD】邮箱'));
				} else {
					if (!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)) {
						callback(new Error('请输入正确格式邮箱'))
					} else {
						callback()
					}
					// let l = value.indexOf('【')
					// let r = value.indexOf('】')
					// let c = value.indexOf('-')
					// if ((l != -1 && r != -1 && l < r) || c != -1) {
					// 	let eValue;
					// 	if (c != -1) {
					// 		let eValueList = value.split('-')
					// 		eValueList.shift()
					// 		// eValue = value.split('-')[1]
					// 		eValue = eValueList.join('-')
					// 	} else {
					// 		eValue = value.split('】')[1]
					// 	}
					// 	console.log(eValue)
					// 	if (!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(eValue)) {
					// 		// this.$refs.ruleForm.validateField('checkPass');
					// 		callback(new Error('请输入正确格式【BD】邮箱或BD-邮箱'));
					// 	} else {
					// 		callback();
					// 	}
					// } else {
					// 	callback(new Error('请输入正确格式【BD】邮箱或BD-邮箱'));
					// }
				}
			};
			return {
				addClientDialog: false, //新增客户模态框
				clientForm: {}, //客户表单
				deleteClientDialog: false, //删除账号模态框
				delId: "", //删除用户id
				openAddType: "1", //1 新增，2 客户管理
				dialogTitle: "新增客户",
				DialogVisible: false, // 分配账号对话框
				inviteForm: {}, //邀请注册表单数据
				customerInviteList: [],
				codeUrl: "http://pin-singapore.aliyun.com/get_img?sessionid=ABC7913107BDAC1A70F35FD1D89C751D&identity=fenxiao_fx.aliyun.com",
				distribution_account: [
					{
						label: "milestonecloud@outlook.com",
						value: 1
					},
					{
						label: "loa2099@outlook.com",
						value: 2
					},
					{
						label: "cangkir@21wintech.com",
						value: 3
					},
					{
						label: "the87cloud@outlook.com",
						value: 4
					},
					{
						label: "sla99999@hotmail.com",
						value: 5
					},
					// {
					// 	label: "HK一级账号",
					// 	value: 1
					// },
					// {
					// 	label: "HK二级-loa",
					// 	value: 2
					// },
					// // {
					// // 	label: "PH一级账号",
					// // 	value: 3
					// // },
					// {
					// 	label: "PH二级账号",
					// 	value: 4
					// },
					// {
					// 	label: "HK二级-mst",
					// 	value: 5
					// },
					// {
					// 	label: "CDN专用",
					// 	value: 9
					// }
				],
				inviteRules: {
					distribution_account: [{
						required: true,
						message: '请选择分销账号',
						trigger: 'change'
					}],
					email: [{
						required: true,
						validator: validateEmail,
						trigger: 'change'
					}],
					unit: [{
						required: true,
						validator: validateBDEmail,
						trigger: 'change'
					}],
					pic_code: [{
						required: true,
						message: '请输入验证码',
						trigger: 'change'
					}],
					customer_sid: [{
						required: true,
						message: '请选择客户',
						trigger: 'change'
					}],
				}, //邀请注册表单验证规则
				realname_status: [{
					value: "",
					label: "全部"
				}, {
					value: 1,
					label: "未实名"
				}, {
					value: 2,
					label: "已通过线下实名"
				}],
				contact_type: [{
					value: "",
					label: "全部"
				}, {
					value: 1,
					label: "Telegram"
				}, {
					value: 2,
					label: "手机"
				}, {
					value: 3,
					label: "Skype"
				}],
				clientFormRules: {
					customer_name: [{
						required: true,
						message: '请输入',
						trigger: 'change'
					}],
					contact_info: [{
						required: true,
						message: '请输入',
						trigger: 'change'
					}],
					// email: [{
					// 	required: true,
					// 	validator: validateEmail, ///^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
					// 	trigger: 'change'
					// }],
					BD_id: [{
						required: true,
						message: '请输入',
						trigger: 'change'
					}],
					account_type: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					realname_status: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					contract_status: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					is_open: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					discount: [{
						required: true,
						trigger: 'change',
						validator: validateDiscount
					}],
				}, //客户表单校验规则
				BDList: [],
				multipleSelection: [],
				remoteTime: null,
				remoteLoading: false
			}
		},
		computed: {

		},
		methods: {
			async getData() {
				let res = (await list(this.searchForm)).data
				this.tableData = res.records
				this.total = res.total_count
				this.tableData.map(e=>{
					e.contact_info_string = this.getContactString(e.contact_type)
					e.account_type_string = e.account_type == 1?"伙伴":"客户"
					e.contract_status_string = e.contract_status == 0?"":e.contract_status == 1?"未签约":"已签约"
					e.is_open_string = e.is_open == 2?"已开通":e.is_open == 1?"未开通":""
					e.discount_string = e.discount?e.discount + "%":""
					return e
				})
			},
			/* 更改排序方式 */
			changeSort(e) {
				console.log(e)
				if (e.prop == "associated_count") {
					if (e.order == "ascending") {
						this.searchForm.associated_count_sort = "asc"
					} else if (e.order == "descending") {
						this.searchForm.associated_count_sort = "desc"
					} else {
						delete this.searchForm['associated_count_sort']
					}
				}
				this.getData()
			},
			/* 新增客户 */
			openAdd(i, row) {
				this.openAddType = i
				this.addClientDialog = true
				this.dialogTitle = this.openAddType == 1 ? '新增客户' : '客户管理'
				if (this.openAddType == 1) {
					this.clientForm = {
						contact_type: 1,
						realname_status: 1,
						account_type: 2,
						contract_status: 0,
						is_open: 0,
						discount: 100
					}
				} else {
					this.clientForm = JSON.parse(JSON.stringify(row))
					for (let i = 0; i < this.BDList.length; i++) {
						if (this.clientForm.BD == this.BDList[i].username) {
							// this.clientForm.BD_id = this.BDList[i].id
							this.$set(this.clientForm,"BD_id",this.BDList[i].id)
						}
					}
					console.log(this.clientForm)
				}
			},
			//邀请注册弹框
			openYq() {
				this.DialogVisible = true
				this.inviteForm = {
					distribution_account: 1
				}
				this.codeUrl =
					"http://pin-singapore.aliyun.com/get_img?sessionid=ABC7913107BDAC1A70F35FD1D89C751D&identity=fenxiao_fx.aliyun.com"
				customerInviteList({}).then(res => {
					this.customerInviteList = res.data.records
				})
				this.reSetCode()
			},
			//刷新验证码
			reSetCode() {
				// this.showCode = false
				this.$nextTick(() => {
					//this.$refs.codeImg.src
					this.$refs.codeImg.src = this.codeUrl + "&random=" + Math.random()
				})
			},
			async commitYq() {
				this.$refs["inviteForm"].validate(async (valid) => {
					if (valid) {
						for (let i = 0; i < this.customerInviteList.length; i++) {
							if (this.customerInviteList[i].sid == this.inviteForm.customer_sid) {
								this.inviteForm.customer_name = this.customerInviteList[i].customer_name
								break
							}
						}
						let res = await inviteRegister(this.inviteForm)
						if (res.data.err_code) {
							this.$message.error(res.data.err_msg)
						} else {
							this.$message.success('邀请成功')
							this.DialogVisible = false
							this.getData()
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			/* 确认添加客户 */
			async commitAddClient() {
				this.$refs["clientForm"].validate(async (valid) => {
					if (valid) {
						for (let i = 0; i < this.BDList.length; i++) {
							if (this.clientForm.BD_id == this.BDList[i].id) {
								// this.clientForm.BD_id = this.BDList[i].id
								this.$set(this.clientForm,"BD",this.BDList[i].username)
								break
							}
						}
						if (this.openAddType == 1) {
							await add(this.clientForm)
						} else {
							console.log('管理')
							await edit(this.clientForm)
						}
						this.$message.success(this.openAddType == 1 ? '新增用户成功' : '编辑用户成功')
						this.addClientDialog = false
						this.getData()
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			getContactImg(typeNum) {
				let src = ""
				switch (typeNum) {
					case 1:
						src = require('../../assets/customer/tg.png')
						break
					case 2:
						src = require('../../assets/customer/phone.png')
						break
					case 3:
						src = require('../../assets/customer/skype.png')
						break
					case 4:
						src = require('../../assets/customer/email.png')
						break
				}
				return src
			},
			getContactString(typeNum) {
				let src = ""
				switch (typeNum) {
					case 1:
						src = "Telegram"
						break
					case 2:
						src = "phone"
						break
					case 3:
						src = "skype"
						break
					case 4:
						src = "emial"
						break
				}
				return src
			},
			async commitDel() {
				await del(this.delId)
				this.$message.success('删除成功')
				this.getData()
				this.deleteClientDialog = false
			},
			async exportExcel() {
				let searchParams = JSON.parse(JSON.stringify(this.searchForm))
				// searchParams.type = 'getFile'
				let che = await checkExcel(searchParams)
				console.log(che)
				if (che.err_code != 'SYS000') {
					this.$message.warning(che.err_msg)
					return
				}
				// return
				downloadFileByBase64(che.data.data, '客户列表.xlsx')
				this.$message.success('导出成功')
				// let res = await excel(searchParams)
				// const fileName = '客户列表.xlsx'
				// if ('download' in document.createElement('a')) { // 非IE下载
				// 	const blob = new Blob([res], {
				// 		type: 'application/ms-excel'
				// 	})
				// 	const elink = document.createElement('a')
				// 	elink.download = fileName
				// 	elink.style.display = 'none'
				// 	elink.href = URL.createObjectURL(blob)
				// 	document.body.appendChild(elink)
				// 	elink.click()
				// 	URL.revokeObjectURL(elink.href) // 释放URL 对象
				// 	document.body.removeChild(elink)
				// }
			},
			handleSelectionChange(e) {
				console.log(e)
				this.multipleSelection = e
				this.multipleSelection = this.multipleSelection.map(e => {
					e.realname_statusString = e.realname_status == 1 ? '未实名' : "已线下实名"
					return e
				})
			},
			downloadExcel() {
				this.$confirm('确定下载列表文件?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					// this.excelData = this.multipleSelection; // multipleSelection是一个数组，存储表格中选择的行的数据。
					this.export2Excel();
				}).catch(() => {

				});
			},
			// 数据写入excel
			export2Excel() {
				var that = this;
				// const {
				// 	export_json_to_excel
				// } = require('@/excel/export2Excel'); // 这里必须使用绝对路径，使用@/+存放export2Excel的路径
				const tHeader = ['客户名称', '联系方式', '联系方式类型','类型', '实名状态','签约状态','已开通二级平台','伙伴折扣', '已关联账号数', '归属BD', '创建时间']; // 导出的表头名信息
				const filterVal = ['customer_name', 'contact_info', 'contact_info_string','account_type_string', 'realname_statusString','contract_status_string',"discount_string","", 'associated_count',
					'BD', 'create_time'
				]; // 导出的表头字段名，需要导出表格字段名
				const list = that.multipleSelection;
				const data = that.formatJson(filterVal, list);

				export_json_to_excel(tHeader, data, '客户列表'); // 导出的表格名称，根据需要自己命名
			},
			// 格式转换，直接复制即可
			formatJson(filterVal, jsonData) {
				console.log(jsonData)
				return jsonData.map(v => filterVal.map(j => v[j]));
				// return []
			},
			changeContactType(e) {
				console.log(e)
				var validateEmail = (rule, value, callback) => {
					console.log('email' + value)
					if (value == "" || !value) {
						console.log('请输入邮箱')
						callback(new Error('请输入邮箱'));
					} else {
						if (!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)) {
							// this.$refs.ruleForm.validateField('checkPass');
							callback(new Error('请输入正确的邮箱'));
						} else {
							callback();
						}
					}
				};
				if (e == 4) {
					this.clientFormRules.contact_info = [{
						required: true,
						validator: validateEmail, ///^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
						trigger: 'change'
					}]
				} else {
					this.clientFormRules.contact_info = [{
						required: true,
						message: '请输入',
						trigger: 'change'
					}]
				}
			},
			async remoteCustomer(e) {
				console.log(e)
				clearTimeout(this.remoteTime)
				this.remoteTime = setTimeout(async () => {
					this.remoteLoading = true
					this.customerInviteList = (await customerInviteList({
						customer_name: e
					})).data.records
					this.remoteLoading = false
				}, 300)
			},
			changeVis(e) {
				!e && this.remoteCustomer('')
			},
			changeFx(e) {
				console.log(e)
				if (e == 5) {
					this.codeUrl =
						"http://pin-singapore.aliyun.com/get_img?sessionid=130DE07976B1CB45D95B6F68CA4D3082&identity=fenxiao_fx.aliyun.com"
				} else if (e == 2) {
					this.codeUrl =
						"http://pin-singapore.aliyun.com/get_img?sessionid=E7D30DE4EA36B9712656928CE36B38D2&identity=fenxiao_fx.aliyun.com"
				} else if (e == 1) {
					this.codeUrl =
						"http://pin-singapore.aliyun.com/get_img?sessionid=ABC7913107BDAC1A70F35FD1D89C751D&identity=fenxiao_fx.aliyun.com"
				} else if (e == 3) {
					this.codeUrl =
						"http://pin-singapore.aliyun.com/get_img?sessionid=77D91D367B2DE362230C9BC48607D0F0&identity=fenxiao_fx.aliyun.com"
				} else {
					this.codeUrl =
						"https://pin-singapore.aliyun.com/get_img?sessionid=45443EAA83548CC5E82A4BDD572D5B0E&identity=fenxiao_fx.aliyun.com"
				}
			}
		},
		created() {
			BDList().then(res => {
				this.BDList = res.data.records
			})
			if (this.$route.query.addCustomer) {
				this.addClientDialog = true
			}
		}
	}
</script>

<style lang="scss">
	.cient-list-contianer {

		/* 新增客户联系方式 */
		.contact-form-item {
			align-items: flex-start;

			.el-input {
				margin-top: .4rem;
			}
		}

		.free-dj {
			display: flex;
			align-items: center;

			div {
				width: 6px;
				height: 6px;
				border-radius: 50%;
				margin-right: .4rem;
				flex-shrink: 0;
			}

			.free {
				background-color: #74CB86;
			}

			.dj {
				background-color: #FF4D4F;
			}
		}

		.my-page {
			justify-content: space-between;
			padding-left: 10px;
		}

		.el-table__fixed-right::before,
		.el-table__fixed::before {
			content: "";
			width: 0;
			height: 0;
		}

		.invite-dialog {
			.el-dialog {
				min-width: 420px !important;
			}
		}

	}

	.attention {
		margin-top: 2.5rem;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: .8rem;
			height: .8rem;
			margin-right: .4rem;
		}
	}

	.error-button {
		background-color: #FF4D4F;
		border-color: #FF4D4F;
		color: #ffffff;
	}

	.delete-dialog {
		.el-dialog__body {
			padding: 0.3rem 2.7rem 3.6rem 1rem;
		}


	}

	.add-select {
		position: sticky;
		bottom: -1px;
		z-index: 100000;
		background-color: #e5e5e5;
		height: 30px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		font-size: 12px;
		color: #5c5c5c;
	}
</style>
